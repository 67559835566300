import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const FooterContainer = styled.footer`
	background-color: #0063B2FF;
`

export const FooterWrap = styled.div`
	padding: 5px 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1100px;
	margin: 0 auto;

`

export const FooterLinksContainer = styled.div`
	display: flex;
	justify-content: center;

	@media screen and (max-width: 830px) {
		padding-top: 32px;
	}
`

export const FooterLinkWrapper = styled.div`
	display: flex;

	@media screen and (max-width: 830px) {
		flex-direction: column;

	}

`

export const FooterLinkItems = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 5px;
	text-align: left;
	width: 500px;
	box-sizing: border-box;
	color: #fff;

	@media screen and (max-width: 1030px) {
		text-align: center;

	}
	@media screen and (max-width: 420px) {
		margin: 0;
		padding: 10px;
		width: 100%;

	}
`

export const FooterLinkTitle = styled.h1`
	font-size: 14px;
	margin-bottom: 16px;

`

export const FooterLink = styled(Link)`
	color: #fff;
	text-decoration: none;
	margin-bottom: 0.5rem;
	font-size: 14px;
	padding-right: 0px;

	@media screen and (max-width: 830px ) {
		justify-content: center !important
		padding-right: 0px !important
	
	}


	&:hover {
		color: #42EADDFF;
		transition: 0.3s ease-out;
	}
`
export const ImgWrapper = styled.div`
		padding-right: 0px;

		@media screen and (max-width: 830px ) {
		justify-content: center !important
		padding-right: 0px !important
	
	}

`
export const Img = styled.img`
	width: 60px;
	height: 40px;
	margin-left: 10px;
	margin-right: 10px;
	@media screen and (max-width: 400px ) {
		width: 40px;
		height: 20px;
	
	}
	@media screen and (max-width: 300px ) {
		height: 35px;
	
	}


`

export const SocialMedia = styled.section`
	max-width: 1000px;
	width: 100%
`

export const SocialMediaWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1100px;
	margin: 30px auto 0 auto;

	@media screen and (max-width: 830px) {
		flex-direction: column;
	}
`

export const SocialLogo = styled(Link)`
	color: #fff;
	justify-self: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	font-weight: bold;
`
export const WebsiteRights = styled.small`
	color: #fff;
	margin-bottom: 16px;
`

export const SocialIcons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 240px;
`

export const SocialIconLink = styled.a`
	color: #fff;
	font-size: 24px;
`