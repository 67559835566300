import {React,useState,useEffect} from 'react'
import  './Pictures.css'
import BeatLoader from "react-spinners/BeatLoader";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CloseIcon from '@mui/icons-material/Close';
const Pictures1 = () => {
    //storing pictures 
    let data=[ 
   
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269684787_244267161121125_2989154377406216043_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269684787_244267161121125_2989154377406216043_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269673870_243693947845113_2501435200330505881_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269673870_243693947845113_2501435200330505881_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269473396_243694057845102_2564428152174537516_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269473396_243694057845102_2564428152174537516_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/268441645_242491474632027_3605343886567693396_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/268441645_242491474632027_3605343886567693396_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267931524_242491444632030_608906014744397801_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267931524_242491444632030_608906014744397801_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267890900_242490314632143_5144446368066787578_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267890900_242490314632143_5144446368066787578_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267735519_242490761298765_4290984762990291412_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267735519_242490761298765_4290984762990291412_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267725827_242491057965402_9042412397723129759_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267725827_242491057965402_9042412397723129759_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267706407_242492104631964_6365710169877083504_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267706407_242492104631964_6365710169877083504_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267698992_242490491298792_909573402595739895_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267698992_242490491298792_909573402595739895_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267684383_242491611298680_2091870497117793430_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267684383_242491611298680_2091870497117793430_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674867_242491841298657_6364827475072683982_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674867_242491841298657_6364827475072683982_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674601_242491601298681_4415201037785071483_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674601_242491601298681_4415201037785071483_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267630145_242489977965510_7621186877139743123_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267630145_242489977965510_7621186877139743123_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267560202_242491544632020_3354465769487330666_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267560202_242491544632020_3354465769487330666_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267381857_239749621572879_2771534714815035749_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267381857_239749621572879_2771534714815035749_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267141387_239749141572927_9145862875633477763_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267141387_239749141572927_9145862875633477763_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266817284_239750064906168_5613979936665346909_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266817284_239750064906168_5613979936665346909_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266689052_239749314906243_3888117847050454783_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266689052_239749314906243_3888117847050454783_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266446819_239749498239558_1582735691556649369_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266446819_239749498239558_1582735691556649369_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266362138_239748951572946_6463096361948113231_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266362138_239748951572946_6463096361948113231_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266323382_239748784906296_3215149253208981960_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266323382_239748784906296_3215149253208981960_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266309472_239750284906146_6317824324315358524_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266309472_239750284906146_6317824324315358524_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266241673_239750468239461_3535549309215376208_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266241673_239750468239461_3535549309215376208_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266090151_239749571572884_2829807702702781573_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/266090151_239749571572884_2829807702702781573_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265973433_239748698239638_4836291737838117659_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265973433_239748698239638_4836291737838117659_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265962835_239749714906203_495335619698033592_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265962835_239749714906203_495335619698033592_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265932551_239751301572711_1681719445114008106_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265932551_239751301572711_1681719445114008106_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265646050_239750578239450_5249112933048937722_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265646050_239750578239450_5249112933048937722_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265605978_239749394906235_2950725791458452854_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/265605978_239749394906235_2950725791458452854_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/264579914_235629985318176_8820156269748112075_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/264579914_235629985318176_8820156269748112075_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/264213686_235630325318142_4869956682175261101_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/264213686_235630325318142_4869956682175261101_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263773571_235635008651007_4509722500680071399_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263773571_235635008651007_4509722500680071399_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263692374_235629408651567_3898664381840274112_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263692374_235629408651567_3898664381840274112_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263505871_235628265318348_1168214398438719138_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263505871_235628265318348_1168214398438719138_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263415215_235632041984637_7940730609348552555_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263415215_235632041984637_7940730609348552555_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263184623_235629038651604_816277823619070074_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/263184623_235629038651604_816277823619070074_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261726793_230499465831228_3239188774172512148_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261726793_230499465831228_3239188774172512148_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261432785_230485415832633_2845677400392616015_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261432785_230485415832633_2845677400392616015_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261412965_230486512499190_8829818002916359245_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261412965_230486512499190_8829818002916359245_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261184157_230486639165844_1480194564022770409_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261184157_230486639165844_1480194564022770409_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261161537_230846022463239_8133082021859305348_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261161537_230846022463239_8133082021859305348_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261160908_230485845832590_2302544642585080171_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/261160908_230485845832590_2302544642585080171_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260980071_230844845796690_8049159262324131662_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260980071_230844845796690_8049159262324131662_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260959947_230845585796616_8101059084847444406_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260959947_230845585796616_8101059084847444406_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260936755_230499555831219_4763058267664807665_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260936755_230499555831219_4763058267664807665_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260784269_230847682463073_6969006059439002053_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260784269_230847682463073_6969006059439002053_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260536996_230845829129925_8099904048997605275_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260536996_230845829129925_8099904048997605275_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260460361_230499662497875_2914937641333819697_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260460361_230499662497875_2914937641333819697_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260324644_230491229165385_5491674168921383289_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/260324644_230491229165385_5491674168921383289_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257629266_223237863224055_812861655377156394_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257629266_223237863224055_812861655377156394_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257602761_223237989890709_3424606393092200850_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257602761_223237989890709_3424606393092200850_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257549696_223238123224029_4538386001640486773_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257549696_223238123224029_4538386001640486773_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257384492_223238536557321_4723168874293831761_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/257384492_223238536557321_4723168874293831761_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252769609_215730367308138_6575255341530708889_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252769609_215730367308138_6575255341530708889_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252474610_215739530640555_6475758050431991826_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252474610_215739530640555_6475758050431991826_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252319001_215729627308212_5402791823472393043_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252319001_215729627308212_5402791823472393043_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252267089_215730113974830_6659341000452633549_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/252267089_215730113974830_6659341000452633549_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269987851_247156994165475_2510784024041619808_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269987851_247156994165475_2510784024041619808_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269982530_247156907498817_1191684673018821501_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269982530_247156907498817_1191684673018821501_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269982530_247156907498817_1191684673018821501_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269982530_247156907498817_1191684673018821501_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269779283_244992957715212_3761206202718943455_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269779283_244992957715212_3761206202718943455_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269769112_244267071121134_3250835776033838077_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269769112_244267071121134_3250835776033838077_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269731770_244993101048531_6125771889893116600_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269731770_244993101048531_6125771889893116600_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269720878_244267724454402_5296144724592683252_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269720878_244267724454402_5296144724592683252_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269717579_244267934454381_1329626189812899750_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269717579_244267934454381_1329626189812899750_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269698640_243692351178606_169979887777351023_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269698640_243692351178606_169979887777351023_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269685661_243694171178424_8283285545228430219_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269685661_243694171178424_8283285545228430219_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269684787_244267161121125_2989154377406216043_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269684787_244267161121125_2989154377406216043_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269673870_243693947845113_2501435200330505881_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269673870_243693947845113_2501435200330505881_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269473396_243694057845102_2564428152174537516_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/269473396_243694057845102_2564428152174537516_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/268441645_242491474632027_3605343886567693396_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/268441645_242491474632027_3605343886567693396_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267931524_242491444632030_608906014744397801_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267931524_242491444632030_608906014744397801_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267890900_242490314632143_5144446368066787578_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267890900_242490314632143_5144446368066787578_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267735519_242490761298765_4290984762990291412_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267735519_242490761298765_4290984762990291412_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267725827_242491057965402_9042412397723129759_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267725827_242491057965402_9042412397723129759_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267706407_242492104631964_6365710169877083504_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267706407_242492104631964_6365710169877083504_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267698992_242490491298792_909573402595739895_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267698992_242490491298792_909573402595739895_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267684383_242491611298680_2091870497117793430_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267684383_242491611298680_2091870497117793430_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674867_242491841298657_6364827475072683982_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674867_242491841298657_6364827475072683982_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674601_242491601298681_4415201037785071483_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267674601_242491601298681_4415201037785071483_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267630145_242489977965510_7621186877139743123_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267630145_242489977965510_7621186877139743123_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267560202_242491544632020_3354465769487330666_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267560202_242491544632020_3354465769487330666_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267381857_239749621572879_2771534714815035749_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267381857_239749621572879_2771534714815035749_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267141387_239749141572927_9145862875633477763_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/267141387_239749141572927_9145862875633477763_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/248417636_210190297862145_1860186751798706406_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/248417636_210190297862145_1860186751798706406_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247939204_210187297862445_7794204626256251620_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247939204_210187297862445_7794204626256251620_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247866555_210188237862351_5357970859820352803_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247866555_210188237862351_5357970859820352803_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247674379_209660081248500_6925107816813056236_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247674379_209660081248500_6925107816813056236_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247667247_210548224493019_7612941762277142825_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247667247_210548224493019_7612941762277142825_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247623047_209630991251409_7191008745601540442_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247623047_209630991251409_7191008745601540442_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247517106_210187837862391_8430434072637076340_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247517106_210187837862391_8430434072637076340_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247484790_210189101195598_6404163871221051594_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247484790_210189101195598_6404163871221051594_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247409794_209660071248501_2738146229717116145_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247409794_209660071248501_2738146229717116145_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247396705_209627437918431_8608937395298077197_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247396705_209627437918431_8608937395298077197_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247396650_210186664529175_5533399617656162687_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247396650_210186664529175_5533399617656162687_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247392183_209631941251314_3098600497685654366_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247392183_209631941251314_3098600497685654366_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247260888_209827011231807_7683364118789569847_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247260888_209827011231807_7683364118789569847_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247242226_210187721195736_3797728246417841233_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247242226_210187721195736_3797728246417841233_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247187539_209660144581827_7362433340027834626_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247187539_209660144581827_7362433340027834626_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247187539_209660144581827_7362433340027834626_n%20(2).jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247187539_209660144581827_7362433340027834626_n%20(2).jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247187539_209660144581827_7362433340027834626_n%20(1).jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247187539_209660144581827_7362433340027834626_n%20(1).jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247127855_209626767918498_5947478662962795810_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247127855_209626767918498_5947478662962795810_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247121695_209628881251620_2644631019081721978_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247121695_209628881251620_2644631019081721978_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247113758_209660051248503_1206729385246468853_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/247113758_209660051248503_1206729385246468853_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/246146540_206034018277773_2060952756065104600_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/246146540_206034018277773_2060952756065104600_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/246137941_206062361608272_3347209155323180841_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/246137941_206062361608272_3347209155323180841_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245680079_206062518274923_5471607686048187424_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245680079_206062518274923_5471607686048187424_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245646897_206033931611115_2630549385878306077_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245646897_206033931611115_2630549385878306077_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245630840_206062078274967_7012841297176012651_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245630840_206062078274967_7012841297176012651_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245610896_206034061611102_6710272019596687458_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245610896_206034061611102_6710272019596687458_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245548723_206062124941629_6515982256164894652_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245548723_206062124941629_6515982256164894652_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245491672_206033861611122_5355802689601796743_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245491672_206033861611122_5355802689601796743_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245489468_206034414944400_8924906483146373887_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245489468_206034414944400_8924906483146373887_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245469389_206034884944353_6843629224908407406_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245469389_206034884944353_6843629224908407406_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245456182_206062648274910_8843412956982795079_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245456182_206062648274910_8843412956982795079_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245449865_206062718274903_1367609967543669864_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/245449865_206062718274903_1367609967543669864_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244965741_200479105499931_4174145787692754714_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244965741_200479105499931_4174145787692754714_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244710206_199202798960895_2086838196236746629_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244710206_199202798960895_2086838196236746629_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244706536_200478155500026_4326334537212990058_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244706536_200478155500026_4326334537212990058_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244665468_200477998833375_3103367276824959253_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244665468_200477998833375_3103367276824959253_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244661406_199202685627573_553626699480426241_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244661406_199202685627573_553626699480426241_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244661085_200478058833369_8588175195758298975_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244661085_200478058833369_8588175195758298975_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244660710_199776128903562_8722938423665135855_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244660710_199776128903562_8722938423665135855_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244655247_199202585627583_7799293136442657998_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244655247_199202585627583_7799293136442657998_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244629461_199775928903582_5002509259527875078_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244629461_199775928903582_5002509259527875078_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244613031_199202312294277_234828392818316621_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244613031_199202312294277_234828392818316621_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244555821_199202632294245_87246740964364197_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244555821_199202632294245_87246740964364197_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244531724_197495429131632_3529334456348702488_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244531724_197495429131632_3529334456348702488_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244525108_199214022293106_5299756765565382539_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244525108_199214022293106_5299756765565382539_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244478763_199213842293124_904768617254599545_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244478763_199213842293124_904768617254599545_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244380540_197495492464959_3975462974654252158_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244380540_197495492464959_3975462974654252158_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244350033_197495335798308_2999723696612720836_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244350033_197495335798308_2999723696612720836_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244343403_197495452464963_3532759870762107904_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244343403_197495452464963_3532759870762107904_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244341573_197495379131637_4253083740103738748_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/244341573_197495379131637_4253083740103738748_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/243162610_193641682850340_4188794971477522325_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/243162610_193641682850340_4188794971477522325_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/243160982_193641192850389_5471980371029259799_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/243160982_193641192850389_5471980371029259799_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/243137543_193641936183648_5699909904883905435_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/243137543_193641936183648_5699909904883905435_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/242151075_210547897826385_1106142525446289944_n.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/242151075_210547897826385_1106142525446289944_n.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/108.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/108.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/109.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/109.jpg?raw=true",
                
            },
        
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/110.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/110.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/111-2.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/111-2.jpg?raw=true",
                
            },
             {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/86.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/86.jpg?raw=true",
                
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/87.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/87.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/88.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/88.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/89.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/89.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/90.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/90.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/91.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/91.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/92.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/92.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/93.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/93.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/94.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/94.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/95.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/95.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/96.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/96.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/97.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/97.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/98.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/98.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/99.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/99.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/100.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/100.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/101.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/101.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/102.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/102.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/103.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/103.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/104.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/104.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/105.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/105.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/106.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/106.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/107.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/107.jpg?raw=true",
        
            },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/43.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/43.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/42.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/42.jpg?raw=true",
            
           },
            
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/44.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/44.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/45.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/45.jpg?raw=true",
            
           },
        
           
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/6.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/6.jpg?raw=true",
        },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/7.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/7.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/8.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/8.jpg?raw=true",
        },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/9.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/9.jpg?raw=true",
           
           },
         
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/10.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/10.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/11.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/11.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/12.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/12.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/13.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/13.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/14.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/14.jpg?raw=true",
         
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/15.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/15.jpg?raw=true",
         
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/16.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/16.jpg?raw=true",
         
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/17.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/17.jpg?raw=true",
         
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/18.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/18.jpg?raw=true",
         
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/19.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/19.jpg?raw=true",
         
            
           },
          
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/20.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/20.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/21.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/21.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/22.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/22.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/23.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/23.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/24.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/24.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/25.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/25.jpg?raw=true",
            
           },
           {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/26.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/26.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/27.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/27.jpg?raw=true",
            
           }
           ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/28.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/28.jpg?raw=true",
            
           }
           ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/29.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/29.jpg?raw=true",
            
           }
           ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/30.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/30.jpg?raw=true",
            
           }
           ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/31.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/31.jpg?raw=true",
            }
           ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/32.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/32.jpg?raw=true",
            
           }
           ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/33.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/33.jpg?raw=true",
            
           }
           ,
         
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/34.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/34.jpg?raw=true",
            
           }
           ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/35.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/35.jpg?raw=true",
            
           }
             ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/36.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/36.jpg?raw=true",
            
           }
             ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/37.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/37.jpg?raw=true",
            
           }  ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/38.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/38.jpg?raw=true",
            
           } ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/39.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/39.jpg?raw=true",
            
           }
          ,
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/40.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/40.jpg?raw=true",
            
           }
          ,
         
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/41.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/41.jpg?raw=true",
            
           },
           
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/46.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/46.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/47.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/47.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/48.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/48.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/49.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/49.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/50.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/50.jpg?raw=true",
            
           },
           {
               imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/50.jpg?raw=true",
               thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/50.jpg?raw=true",
           
          },
          {
              imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/51.jpg?raw=true",
              thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/51.jpg?raw=true",
          
         },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/52.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/52.jpg?raw=true",
            
           },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/53.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/53.jpg?raw=true",
            
           },
           {
               imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/54.jpg?raw=true",
               thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/54.jpg?raw=true",
           
          },
          {
              imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/55.jpg?raw=true",
              thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/55.jpg?raw=true",
          
         },
         {
             imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/56.jpg?raw=true",
             thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/56.jpg?raw=true",
         
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/57.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/57.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/58.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/58.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/59.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/59.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/60.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/60.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/61.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/61.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/62.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/62.jpg?raw=true",
        
        },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/63.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/63.jpg?raw=true",
            
           },
           {
               imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/64.jpg?raw=true",
               thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/64.jpg?raw=true",
           
          },
          {
              imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/65.jpg?raw=true",
              thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/65.jpg?raw=true",
          
         },
            {
                imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/66.jpg?raw=true",
                thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/66.jpg?raw=true",
            
           },
           {
               imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/67.jpg?raw=true",
               thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/67.jpg?raw=true",
           
          },
          {
              imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/68.jpg?raw=true",
              thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/68.jpg?raw=true",
          
         },
         {
             imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/69.jpg?raw=true",
             thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/69.jpg?raw=true",
         
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/70.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/70.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/71.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/71.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/72.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/72.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/73.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/73.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/74.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/74.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/75.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/75.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/76.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/76.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/77.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/77.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/78.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/78.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/79.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/79.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/80.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/80.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/81.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/81.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/82.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/82.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/83.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/83.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/84.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/84.jpg?raw=true",
        
        },
        {
            imgSrc:   "https://github.com/khalil-ryu/cspdd-images/blob/master/85.jpg?raw=true",
            thumbnail:   "https://github.com/khalil-ryu/cspdd-images/blob/master/85.jpg?raw=true",
        
        }
    ];
        
    for (let i = 1; i < 257; i++) {
        let photo = {};
        photo["imgSrc"] = `https://github.com/khalil-ryu/cspdd-images/blob/master/project-one/${i}.jpg?raw=true`
        data.unshift(photo);
    }
    const[loading,setLoading]=useState(false)
    const [model,setModel]=useState(false)
    const [tempimgSrc,setTempimgSrc]=useState('')
    const getImg =(imgSrc)=>{
        setTempimgSrc(imgSrc);
        setModel(true);
    }
    // useEffect(() => {
      
    //     setLoading(true)
    //     setTimeout(()=> {
    //         setLoading(false)
    //     },600000)
    //   ;
    // }, [])
    return ( 
        <>
        <div className={model ? "model open" : "model"}>
            <img src={tempimgSrc} />
            <CloseIcon onClick={()=> setModel(false)}/>
        </div>
      
        <div className='gallery'>
        {/* {loading ? (
        <div className='loading-beta'>
        <BeatLoader color="#36d7b7" size="80" />

        </div>
       ):(
         */}
        {
        data.map((item,index)=> {
            return(
                <div className='pics' key={index} onClick={()=> getImg(item.imgSrc )}>
                    <LazyLoadImage src={item.imgSrc} style={{width:'100%'}} effect="blur"/>
                </div>
            )
        })
    }
    
      </div>
     

    
 
  </>  )
  };
  
  export default Pictures1