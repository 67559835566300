export const homeObjOne = {
	id: 'Activités',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Activites',
	headline: "Création d'un club de développement durable",
	description: "La première action dans le collège ERRACHIDIA Mourouj 3 pour la création d'un club de développement durable ayant pour but de sensibiliser et promouvoir les 17 objectifs de développement durable. Les thèmes qui ont été développés sur une période de trois mois ont été de faire connaître les 17 objectifs de développement durable 2030 aux élèves afin de les sensibiliser à l'importance de ces objectifs et comment impliquer la génération actuelle pour jouer le rôle d'acteur majeur du changement.",
	imgStart: true,
	img: require('../../images/goals.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: false
}
export const homeObjOne1 = {
	id: 'Activités',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Activités',
	headline: "Forum : Le Développement Durale et l'Economie Sociale et Solidaire Leviers De Croissance",
	description: "En Avril 2019 le CSPDD a organisé à la Cité Des sciences un forum avec plusieurs invités pour débattre de la réalité et perspectives de l'agenda 2030 et de l'économie sociale et solidaire. Plusieurs associations de la société civile étaient présentes ainsi qu'un professeur et militant de L' ESS Mr Lotfi Ben Aissa.",
	imgStart: true,
	img: require('../../images/forum.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: false

}
export const homeObjOne2 = {
	id: 'Activités',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Activités',
	headline: "Action au niveau de l'école  SIDI MOUSSA au SERS gouvernorat du KEF",
	description: "Il s'agit d'une action au niveau de l'infrastructure de l'école et la création d'un club de développement durable en milieu rural. Au niveau de l'école, nous avons pu contribuer à élaborer des travaux de peinture et de construction de quatorze fenêtres pour la protection des classes. D'autres activités sont programmées pour l'année 2022 au niveau de l'infrastructure et la création  d'un  club audio-visuel ainsi que la construction d'une cantine et d'une bibliothèque.",
	imgStart: true,
	img: require('../../images/ecole.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: false
}
export const homeObjOne3 = {
	id: 'Activités',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Activités',
	headline: "Conférence sur les indicateurs des 17 objectifs du développement durable",
	description: "Suite à une invitation de l'Institut National Des Statistiques, le Président du CSPDD a présenté  une conférence sur la préparation des indicateurs des ODD et sur le rôle de la société civile dans la mise en place de la plateforme avec toutes les parties prenantes. Cette occasion nous a permis d'atteindre notre objectif d'être parmi les acteurs pour la mise en place des indicateurs des ODD. Le résultat a été bénéfique, notre association a été sollicitée par plusieurs organismes pour animer des conférences sur l'agenda 2030.",
	imgStart: true,
	img: require('../../images/Capture.PNG').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: false
}
export const homeObjOne4 = {
	id: 'Activités',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Activités',
	headline: "Séminaire sur Le Développement Durable ",
	description: "Grâce à l'initiative de Dr Rabiaa Romdhane et du doyen de la FSEG Mahdia, Pr Faouzi Sboui, nous avons pu présenter un séminaire pour les étudiants de première année Master sous le thème : Le Développement Durable, Réalités et Perspectives. ",
	imgStart: true,
	img: require('../../images/seminaire.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: false
}
export const homeObjTwo = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Nos Projets',
	headline: 'De mon école, à ma ville, à mon lac et à ma méditerranée',
	description: "Dans le cadre du programme intégré pour la dépollution de la région du lac de Bizerte, l'action de sensibilisation et d'éducation environnementale intitulée (De mon école, à ma ville, à mon lac et à ma méditerranée) est mise en œuvre par le Centre Stratégique Pour La Promotion Du Développement Durable CSPDD. Cette action sera axée sur la sensibilisation au respect de l'environnement dans le but de construire une culture citoyenne basée sur la promotion de l'agenda 2030 dans toutes ses composantes : environnementale, sociétale et économique. Les activités de cette action  seront  réalisées dans les établissements scolaires des  régions de Menzel Jemil et Menzel Bourguiba. L'objectif ultime, à travers ces activités, est d'éveiller les consciences pour espérer des changements de comportement des jeunes, les futurs décideurs de demain. La durée de l'action est de 18 mois.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/lotfi.jpg').default,
	alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: false,
	link: 'https://www.facebook.com/De-mon-%C3%A9cole-%C3%A0-ma-ville-%C3%A0-mon-lac-et-%C3%A0-ma-m%C3%A9diterran%C3%A9e-101988748682301'
}
export const projectTwo = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Nos Projets',
	headline: 'Voler De Ses Propres Ailes',
	description: "Le Centre Stratégique pour la Promotion du Développement Durable a le plaisir de vous informer que son projet « Voler de ses propres ailes » est retenu suite à l’appel à candidature « Renforcement de la société civile émergente en Tunisie-  Fonds réactif et d’opportunités Mai 2022 », mis en œuvre par un consortium formé par Oxfam et ses partenaires. Le présent projet est financé par l’Union Européenne avec un montant de 48 180,000 TND.Il vise le renforcement de capacités de 4 associations tunisiennes dans la région de Ben Arous qui ont survécu aux conditions difficiles imposées par la pandémie du Covid-19 ou qui ont été récemment constituées et dont la viabilité est réellement menacée. Il s’étale sur cinq mois et propose un programme de formation dans différents domaines notamment la restructuration et la réorganisation des associations, la pollution et les problèmes environnementaux de la région de Ben Arous, la démocratie participative, l’économie sociale et solidaire, le financement et les procédures légales et administratives. Des visites sur le terrain sont également prévues.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/project2.jpg').default,
	alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: false,
	link: 'https://www.facebook.com/De-mon-%C3%A9cole-%C3%A0-ma-ville-%C3%A0-mon-lac-et-%C3%A0-ma-m%C3%A9diterran%C3%A9e-101988748682301'
}
export const homeObjTwo2 = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	imgStart: false,
	img: require('../../images/annonce.jpg').default,
	alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: false,
	link: 'https://www.facebook.com/De-mon-%C3%A9cole-%C3%A0-ma-ville-%C3%A0-mon-lac-et-%C3%A0-ma-m%C3%A9diterran%C3%A9e-101988748682301'
}

export const article1 = {
	id: 'Activités',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'مقال',
	headline: "إزالة التلوث من بحيرة بنزرت : المركز الاستراتيجي للنهوض بالتنمية المستدامة يعدّ برنامجا طموحا يستهدف التلاميذ",
	description: "يعلن المركز الاستراتيجي  للنهوض بالتنمية المستدامة أن مشروعه ( من مدرستي  إلى مدينتي   إلى بحيرتي وإلى بحري الأبيض المتوسط ) قد تم اختياره بعد عملية دعوة لتقديم المشاريع  : دعم عمليات  التحسيس و التوعية  والتربية  البيئية  في ولاية بنزرت  برنامج EcoPact.",
	buttonLabel: 'أكمل القراءة',
	imgStart: true,
	img: require('../../images/lotfi.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://ar.espacemanager.com/%D8%A5%D8%B2%D8%A7%D9%84%D8%A9-%D8%A7%D9%84%D8%AA%D9%84%D9%88%D8%AB-%D9%85%D9%86-%D8%A8%D8%AD%D9%8A%D8%B1%D8%A9-%D8%A8%D9%86%D8%B2%D8%B1%D8%AA-%D8%A7%D9%84%D9%85%D8%B1%D9%83%D8%B2-%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%B1%D8%A7%D8%AA%D9%8A%D8%AC%D9%8A-%D9%84%D9%84%D9%86%D9%87%D9%88%D8%B6-%D8%A8%D8%A7%D9%84%D8%AA%D9%86%D9%85%D9%8A%D8%A9-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%AF%D8%A7%D9%85%D8%A9-%D9%8A%D8%B9%D8%AF%D9%91-%D8%A8%D8%B1%D9%86%D8%A7%D9%85%D8%AC%D8%A7-%D8%B7%D9%85%D9%88%D8%AD%D8%A7-%D9%8A%D8%B3%D8%AA%D9%87%D8%AF%D9%81?fbclid=IwAR02F9fEa5Svqcz2vVyaoxDV0WCZQSIO6Yrb1eGO82fERxbwVc4a6RWejtE'
}

export const article2 = {
	id: 'Articles',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Articles',
	headline: "Annonce d'information pour le public et les parties prenantes sur le financement du projet",
	description: "Le Centre Stratégique Pour La Promotion Du Développement Durable a l'honneur de vous informer que son projet « De mon école, à ma ville, à mon Lac et à ma méditerranée» est retenu suite au processus de l'Appel à propositions : Appui aux actions de sensibilisation et d'éducation environnementale dans le gouvernorat de Bizerte-Programme EcoPact.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/ecopact.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.espacemanager.com/education-et-sensibilisation-des-eleves-la-protection-de-lenvironnement.html?fbclid=IwAR26FDo5lb50FPUXraBNFsY5X17_Q0KEVLMQyC-rG2FADHXkOb_ZLwvEfGA'
}

export const descriptionProjet = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'PHASE DE DEMARRAGE',
	headline: "OS0 : Dissémination et adhésion à l’action",
	description: "A0 : Encadrement et dissémination de l’action" + "    • A0.1. Diffuser l’information sur l’action A0.2.  Inscrire et former les groupes de travail (un groupe/établissement scolaire prévu). A0.3.   Créer un site web et une page Facebook pour le projet.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/ecopact.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.espacemanager.com/education-et-sensibilisation-des-eleves-la-protection-de-lenvironnement.html?fbclid=IwAR26FDo5lb50FPUXraBNFsY5X17_Q0KEVLMQyC-rG2FADHXkOb_ZLwvEfGA'
}
export const descriptionProjet2 = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'PHASE DE MISE EN ŒUVRE DES ACTIVITES',
	headline: "OS1 : -Découvrir son territoire - Stimuler l’action et la participation- Porter un regard critique et autonome sur la situation environnementale des quartiers/villes limitrophes du Lac",
	description: "A1 : Enquête ou sondage d’opinions et débats A1.1. Développer des aptitudes pour l’enquête et d’attitudes d’écoute par la formation  A1.2. Réaliser une enquête d’opinions sur l’état de l’environnement dans les quartiers des élèves. A1.3. Dépouiller, discuter et débattre les résultats obtenus de l’enquête Cette étude pourrait être utilisée par les élèves pour instruire des projets créatifs tels que préconisé dans l’activité A3.2. ",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/ecopact.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.espacemanager.com/education-et-sensibilisation-des-eleves-la-protection-de-lenvironnement.html?fbclid=IwAR26FDo5lb50FPUXraBNFsY5X17_Q0KEVLMQyC-rG2FADHXkOb_ZLwvEfGA'
}
export const activites1 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'PHASE DE MISE EN ŒUVRE DES ACTIVITES',
	headline: "1ére réunion",
	// description: "Dans le cadre du projet « Voler de ses propres ailes », nous avons organisé notre 1ère réunion avec 3 des associations sélectionnées pour communiquer de plus près et leur présenter notre programme de formation et de soutien ainsi que les procédures de participation. Le président du Centre Stratégique pour la Promotion du Développement Durable et le chef de projet ont également échangé avec les jeunes participants des difficultés et/ ou des problèmes de leurs associations. La réunion a eu lieu le 09 octobre 2022 de 9h30 à 11h30 dans une ambiance d’échange et de bonne humeur dans les locaux de la municipalité d’El Mourouj que nous remercions énormément.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites1.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	// link: 'https://www.facebook.com/media/set/?vanity=100086344255915&set=a.112308011657340&__cft__[0]=AZVWDtKV0AqoztcQzFAabvvjYHCuFRcJiO3vjkEnJWLd1owzY9UAEQC3WyBwtyGqi-APd1ajhNnG1Tv5ZeTCkMERataDgg7wgFKE3fi2Te6nsZnFIfbhthj2zLGksZqPpsnO6mSAzVhkZa0eOKO2KNOvKaFqiup8fzn66YZS2mI-GWuZgM59jGhjp2J6n8dx-GEjjAHG-DQ-8KdDn4ygOjYI&__tn__=-UC%2CP-y-R'
	route: "/Activités2_1"
}
export const activites2 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Association Jeunes Créatifs et Conscients Mhamdia ',
	headline: "identification des points forts et des points faibles.",
	description: "Bravo aux participants à la formation 'planification stratégique' dans le cadre du projet Voler de ses propres ailes  par le Centre Stratégique pour la Promotion du Développement Durable/ CSPDD, un projet financé par l'UE. 4 associations de la région de Ben Arous vont bénéficier de tout un programme de renforcement de capacités qui s'étend sur 5 mois.Un grand merci au Coach Sofien Neifoss Asta et au Coworking Space de Jamaity.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites2.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.facebook.com/photo/?fbid=125047123716762&set=a.118560147698793'
}
export const activites3 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Association Jeunes Créatifs et Conscients Mhamdia ',
	headline: "2ème séance de formation dans le cadre de la 1ère activité intitulée 'Planification stratégique'	",
	description: "Diagnostic et anlyse de la situation actuelle des associations participantes/ classement des problèmes internes et externes  par ordre de priorité - Préparation et formulation d'un plan d'action pour une période déterminée Avec l'expert Sofien Neifoss Asta",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites3.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://fb.watch/i2rxAOl0Pp/'
}
export const activites4 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Association Jeunes Créatifs et Conscients Mhamdia ',
	headline: "Atelier de coaching sur le recrutement, l'intégration et la fidélisation de nouveaux éléments dans les associations.	",
	description: "Chaque personne cherche au fond d'elle-même la façon dont elle se comporte avec les autres, la manière avec laquelle elle communique avec de nouveaux bénévoles pour identifier les raisons internes qui n'encouragent peut-être pas leur intégration et fidélisation.	",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites4.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.facebook.com/photo/?fbid=129430083278466&set=a.118560147698793'
}
export const activites5 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Association Jeunes Créatifs et Conscients Mhamdia ',
	headline: "formation sur la réglementation tunisienne en matière de la protection de l'environnement",
	description: "Démarrage de la 1ère séance de formation sur la réglementation tunisienne en matière de la protection de l'environnement, l'état des lieux de la pollution dans la région de Ben Arous et son impact sur l'écosystème. Cette formation est assurée par Mme Faiza Labidi, expert et auditeur en environnement.	",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites5.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.facebook.com/photo/?fbid=133586236196184&set=pcb.133586302862844'
}
export const activites6 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'AJCC X AJIC ',
	headline: " journée porte ouverte",
	description: "Bravo aux 2 associations qui ont organisé leur journée porte ouverte en présence du président du  Centre Stratégique Pour La Promotion Du Développement Durable - CSPDD et de la chef du projet Voler de ses propres ailes dans la Maison des Jeunes Fouchana. AJCC Mhamdia جمعية الشباب المبدع و الواعي بالمحمدية et جمعية شباب مبادرون بالخليدية  ont organisé des activités de team building, ont présenté quelques actions qui représentent le mieux leus associations et discuté la possibilité de se rapprocher plus pour travailler sur des projets communs qui auront un impact durable sur toute la région.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites6.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.facebook.com/photo/?fbid=135695335985274&set=pcb.135695609318580'
}
export const activites7 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Sortie environnementale',
	headline: "Gouvernorat de Ben Arous ",
	description: "L'état des lieux de l'environnement :  situation catastrophique qui perdure et absence de toute volonté de la part des responsables régionaux et nationaux pour chercher des solutions durables. Le ministère de l'environnement avec tous ses organismes ainsi que le ministère de l'agriculture 'se renvoient la balle' et entre-temps, les problèmes écologiques s'agravent et aucune mesure n'est prise. La 1er point choisi par  l'Association Volonté et Citoyenneté est Ahmed Zeyed à Mornag: Comme il n'y a pas une décharge dans toute la région de Mornag pour toutes les ordures et que la municipalité ne cherche pas ou ne trouve pas de solutions, des tas de déchets s'amassent sur le bord de la route et dans l'Oued et constituent une menace réelle à la vie des habitants et à tout l'environnement. Les habitants sont à fois responsables et victimes surtout qu'il n'y a aucun conteneur et aucune assistante des services de la municipalité. Une situation à dénoncer et un appel urgent à la mobilisation de la société civile pour faire pression! Les autres points visités seront évoqués plus tard.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites7.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.facebook.com/photo/?fbid=137823342439140&set=pcb.137823579105783'
}
export const activites8 = {
	id: 'Activites',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Formation',
	headline: "Gouvernance et démocratie participative : 1ère séance	",
	description: "*Différence entre gestion et gouvernance. Différents types de corruption. Pourquoi il y a corruption et à quel niveau? Comment combattre la corruption ?",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/activites8.jpg').default,
	alt: 'Car',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.facebook.com/photo/?fbid=141920328696108&set=pcb.141920395362768	'
}
export const annonces1 = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	headline: 'Voler de ses propres ailes',
	description: "Le Centre Stratégique pour la Promotion du Développement Durable a le plaisir de vous informer que son projet « Voler de ses propres ailes » est retenu suite à l’appel à projets « Renforcement de la société civile émergente en Tunisie-  Fonds réactif et d’opportunités Mai 2022 », mis en œuvre par un consortium formé par Oxfam et ses partenaires. Le présent projet est financé par l’Union Européenne avec un montant de 48 180,000 TND. Il vise le renforcement de capacités de 4 associations tunisiennes dans la région de Ben Arous qui ont survécu aux conditions difficiles imposées par la pandémie du Covid-19 ou qui ont été récemment constituées et dont la viabilité est réellement menacée. Il s’étale sur cinq mois et propose un programme de formation dans différents domaines notamment la restructuration et la réorganisation des associations, la pollution et les problèmes environnementaux de la région de Ben Arous, la démocratie participative, l’économie sociale et solidaire, le financement et les procédures légales et administratives. Des visites sur le terrain sont également prévues.",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: 'https://github.com/khalil-ryu/cspdd-images/blob/master/project-two/project.png?raw=true',
	alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: false,
	link: 'https://www.facebook.com/profile.php?id=100086344255915'
}
export const articles1 = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Articles',
	headline: 'Voler de ses propres ailes',
	description: "Article de presse sur le projet Voler de ses propres ailes mené par le Centre Stratégique Pour La Promotion Du Développement Durable - CSPDD",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/articles1.jpg').default,
	alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.facebook.com/photo/?fbid=139603718927769&set=a.118560147698793'
}
export const articles2 = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Articles',
	headline: 'Voler de ses propres ailes',
	description: "Voler de ses propres ailes , est un projet ambitieux pour le renforcement des capacités de la société civile dans le gouvernorat de Ben Arous. Après avoir été retenu par un consortium formé d'Oxfam et ses partenaires dans le cadre du programme Rose, le projet ...",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/logo.png').default,
	alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.africanchallenges.com/tunisie-execution-du-projet-voler-de-ses-propres-ailes-initie-par-le-centre-strategique-pour-la-promotion-du-developpement-durable-dans-le-gouvernorat-de-ben-arous/?fbclid=IwAR2C0KL8L_VXOySnKDBTvv76RCErz8u8N2sJypSW6H4xkZs2qBWU4qWAsdU'
}
export const articles3 = {
	id: 'NosProjets',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Articles',
	headline: 'Voler de ses propres ailes',
	description: "Articles parus dans le Quotidien et Trait d'Union à propos de la clôture du projet Voler de ses propres ailes et la remise des prix le 19/03/2023 *** Rectification : l'experte mentionnée dans l'article est Hend Aouini et non pas Houda Laouini",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	img: require('../../images/articleQ.jpg').default,
	alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: true,
	link: 'https://www.africanchallenges.com/tunisie-execution-du-projet-voler-de-ses-propres-ailes-initie-par-le-centre-strategique-pour-la-promotion-du-developpement-durable-dans-le-gouvernorat-de-ben-arous/?fbclid=IwAR2C0KL8L_VXOySnKDBTvv76RCErz8u8N2sJypSW6H4xkZs2qBWU4qWAsdU'
}
export const volerannonce = {
	id: 'Annonces',
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	headline: 'Voler de ses propres ailes',
	description: " Le Centre Stratégique pour la Promotion du Développement Durable a le plaisir de vous informer que son projet « Voler de ses propres ailes » est retenu suite à l’appel à projets « Renforcement de la société civile émergente en Tunisie-  Fonds réactif et d’opportunités Mai 2022 », mis en œuvre par un consortium formé par Oxfam et ses partenaires.	Le présent projet est financé par l’Union Européenne avec un montant de 48 180,000 TND.	Il vise le renforcement de capacités de 4 associations tunisiennes dans la région de Ben Arous qui ont survécu aux conditions difficiles imposées par la pandémie du Covid-19 ou qui ont été récemment constituées et dont la viabilité est réellement menacée. Il s’étale sur cinq mois et propose un programme de formation dans différents domaines notamment la restructuration et la réorganisation des associations, la pollution et les problèmes environnementaux de la région de Ben Arous, la démocratie participative, l’économie sociale et solidaire, le financement et les procédures légales et administratives. Des visites sur le terrain sont également prévues. ",
	buttonLabel: 'Savoir Plus',
	imgStart: true,
	//img:" require('../../images/voler.png').default",
	//alt: 'hello',
	dark: false,
	primary: false,
	darkText: true,
	needed: false,
	link: 'https://www.facebook.com/profile.php?id=100086344255915'
}
